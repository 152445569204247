import React, { useState, useEffect, useRef } from 'react';
import { Glasses, Hexagon, Zap, Brain, ChevronLeft, ChevronRight, Phone, Mail } from 'lucide-react';
import image1 from './Media/Image (1).jpg';
import image2 from './Media/Image (1).png';
import image3 from './Media/Image (2).png';
import image4 from './Media/Image (3).png';
import logoImage from './Media/Shroozy Studios white (1).svg';

const AnimatedBackground = () => (
  <div className="fixed inset-0 z-0">
    {[...Array(50)].map((_, i) => (
      <div
        key={i}
        className="absolute bg-blue-200 opacity-10 rounded-full animate-float"
        style={{
          width: `${Math.random() * 20 + 10}px`,
          height: `${Math.random() * 20 + 10}px`,
          left: `${Math.random() * 100}%`,
          top: `${Math.random() * 100}%`,
          animationDuration: `${Math.random() * 10 + 5}s`,
          animationDelay: `${Math.random() * 5}s`,
        }}
      />
    ))}
  </div>
);

const FeatureCard = ({ Icon, title, description }) => (
  <div className="bg-gray-800 bg-opacity-50 p-6 rounded-lg backdrop-blur-sm hover:bg-opacity-70 transition-all duration-300 transform hover:scale-105 border border-gray-700 border-opacity-50">
    <Icon className="w-12 h-12 mb-4 text-blue-300" />
    <h3 className="text-xl font-bold mb-2 text-gray-200">{title}</h3>
    <p className="text-gray-400">{description}</p>
  </div>
);

const ProjectGallery = ({ projects }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextProject = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % projects.length);
  };

  const prevProject = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + projects.length) % projects.length);
  };

  return (
    <div className="relative w-full max-w-4xl mx-auto mt-20 mb-20">
      <h2 className="text-4xl font-bold mb-8 text-center text-gray-300">
        Our Projects
      </h2>
      <div className="relative aspect-video overflow-hidden rounded-xl shadow-2xl">
        <img
          src={projects[currentIndex].image}
          alt={projects[currentIndex].title}
          className="w-full h-full object-cover"
        />
        <div className="absolute bottom-0 left-0 right-0 p-6 bg-gradient-to-t from-gray-900 to-transparent">
          <h3 className="text-2xl font-bold mb-2 text-gray-200">{projects[currentIndex].title}</h3>
          <p className="text-gray-400">{projects[currentIndex].description}</p>
        </div>
      </div>
      <button
        onClick={prevProject}
        className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-gray-800 bg-opacity-50 text-gray-300 p-2 rounded-full hover:bg-opacity-75 transition-all"
      >
        <ChevronLeft size={24} />
      </button>
      <button
        onClick={nextProject}
        className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-gray-800 bg-opacity-50 text-gray-300 p-2 rounded-full hover:bg-opacity-75 transition-all"
      >
        <ChevronRight size={24} />
      </button>
    </div>
  );
};

const HomePage = () => {
  const [scrollY, setScrollY] = useState(0);
  const contactRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => setScrollY(window.scrollY);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToContact = (e) => {
    e.preventDefault();
    contactRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const projects = [
    { image: image4, title: "Doubtful Decks", description: "VR Card game based off of the popular card game 'Bullshit'" },
    { image: image2, title: "Munchies Eating Simulator", description: "Fun VR game where you eat to grow and advance" },
    { image: image3, title: "Horror Mansion VR", description: "Complete tasks and puzzles to escape the VR haunted mansion" },
    { image: image1, title: "Bean Boy Runners", description: "Run and battle against your friends to reach the end of the map" },
  ];

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 via-gray-800 to-gray-700 text-gray-100 overflow-hidden">
      <AnimatedBackground />
      <div className="relative z-10">
        <header className="container mx-auto px-4 py-6 flex justify-between items-center">
          <img src={logoImage} alt="Shroozy Studios Logo" className="h-16 w-auto" />
          <nav>
            <ul className="flex space-x-6">
              {['Home', 'About', 'Projects'].map((item) => (
                <li key={item}>
                  <a href="#" className="text-lg hover:text-blue-300 transition-colors duration-300">{item}</a>
                </li>
              ))}
              <li>
                <a href="#contact" onClick={scrollToContact} className="text-lg hover:text-blue-300 transition-colors duration-300">Contact</a>
              </li>
            </ul>
          </nav>
        </header>
        
        <main className="container mx-auto px-4 py-20">
          <div className="text-center mb-20" style={{ transform: `translateY(${scrollY * 0.5}px)` }}>
            <h1 className="text-6xl font-bold mb-6 text-transparent bg-clip-text bg-gradient-to-r from-gray-200 to-blue-300">
              Welcome to Shroozy Studios
            </h1>
            <p className="text-2xl mb-8 text-gray-300">Crafting Mind-Bending VR Experiences</p>
            <button className="bg-gradient-to-r from-gray-700 to-gray-600 text-gray-200 font-bold py-3 px-8 rounded-full transition-all duration-300 transform hover:scale-105 hover:from-gray-600 hover:to-gray-500 border border-gray-500">
              Explore Our Virtual Worlds
            </button>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-20">
            <FeatureCard Icon={Glasses} title="Immersive VR" description="Step into breathtaking virtual realities" />
            <FeatureCard Icon={Hexagon} title="Cutting-edge Tech" description="Pushing the boundaries of VR innovation" />
            <FeatureCard Icon={Zap} title="Dynamic Gameplay" description="Engage with responsive, exciting mechanics" />
            <FeatureCard Icon={Brain} title="Mind-bending Narratives" description="Experience stories that challenge perception" />
          </div>

          <ProjectGallery projects={projects} />
        </main>
      </div>
      
      <footer ref={contactRef} id="contact" className="relative z-10 py-12 bg-gray-800">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold mb-6 text-gray-200">Contact Us</h2>
          <div className="flex justify-center space-x-8 mb-6">
            <a href="tel:+447447922960" className="flex items-center text-gray-300 hover:text-blue-300 transition-colors duration-300">
              <Phone className="mr-2" size={20} />
              <span>+44 7447 922 960</span>
            </a>
            <a href="mailto:tiwabakree@gmail.com" className="flex items-center text-gray-300 hover:text-blue-300 transition-colors duration-300">
              <Mail className="mr-2" size={20} />
              <span>tiwabakree@gmail.com</span>
            </a>
          </div>
          <p className="text-gray-400">&copy; 2024 Shroozy Studios. Bending Reality, One Pixel at a Time.</p>
        </div>
      </footer>
    </div>
  );
};

export default HomePage;